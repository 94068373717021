export const spacing = {
  "spacing-2": "2px",
  "spacing-4": "4px",
  "spacing-8": "8px",
  "spacing-10": "10px",
  "spacing-12": "12px",
  "spacing-14": "14px",
  "spacing-16": "16px",
  "spacing-18": "18px",
  "spacing-20": "20px",
  "spacing-22": "22px",
  "spacing-24": "24px",
  "spacing-28": "28px",
  "spacing-32": "32px",
  "spacing-36": "36px",
  "spacing-40": "40px",
  "spacing-44": "44px",
  "spacing-48": "48px",
  "spacing-52": "52px",
  "spacing-56": "56px",
  "spacing-60": "60px",
  "spacing-72": "72px",
  "spacing-100": "100px",
};
