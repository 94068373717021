import { type FunctionComponent } from "react";

export const NoteIcon: FunctionComponent<{readonly size?: number}> = ({ size = 20 }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <g id="note">
      <path id="vector" d="M15.8333 2.5H4.16667C3.2475 2.5 2.5 3.2475 2.5 4.16667V15.8333C2.5 16.7525 3.2475 17.5 4.16667 17.5H10.8333C10.9428 17.5002 11.0512 17.4787 11.1523 17.4368C11.2534 17.3949 11.3452 17.3334 11.4225 17.2558L17.2558 11.4225C17.3243 11.3515 17.3797 11.2688 17.4192 11.1783C17.4308 11.1533 17.4375 11.1275 17.4467 11.1008C17.4704 11.0311 17.4847 10.9585 17.4892 10.885C17.4908 10.8675 17.5 10.8508 17.5 10.8333V4.16667C17.5 3.2475 16.7525 2.5 15.8333 2.5ZM4.16667 4.16667H15.8333V10H10.8333C10.6123 10 10.4004 10.0878 10.2441 10.2441C10.0878 10.4004 10 10.6123 10 10.8333V15.8333H4.16667V4.16667ZM11.6667 14.655V11.6667H14.655L11.6667 14.655Z" fill="currentColor"/>
    </g>
  </svg>
);
