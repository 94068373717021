import { type MantineThemeColors } from "@mantine/styles/lib/theme/types/MantineColor";

type MantineThemeColorsReadonly = {
  [key in keyof MantineThemeColors]: Readonly<[string, string, string, string, string, string, string, string, string, string]>;
};

export const colors = {
  "brand-01": [
    "#FBDFDD", // 0
    "#EBAEA9", // 1
    "#D26D65", // 2
    "#BA3333", // 3
    "#A90000", // 4
    "#760000", // 5
    "#000000",
    "#000000",
    "#000000",
    "#000000",
  ],
  "brand-02": [
    "#FCEEDD", // 0
    "#FFD3B9", // 1
    "#FFBD96", // 2
    "#FFA773", // 3
    "#FF9150", // 4
    "#D86420", // 5
    "#000000",
    "#000000",
    "#000000",
    "#000000",
  ],
  "cc-cases": [
    "#F4F6FE", // 0
    "#E9EDFD", // 1
    "#C7D3FB", // 2
    "#96A8E4", // 3
    "#5B74C7", // 4
    "#3A4C88", // 5
    "#000000",
    "#000000",
    "#000000",
    "#000000",
  ],
  "cc-dictionary": [
    "#F3F9F5", // 0
    "#E3F1EA", // 1
    "#C3DFCF", // 2
    "#A5CBB5", // 3
    "#83B899", // 4
    "#68937A", // 5
    "#000000",
    "#000000",
    "#000000",
    "#000000",
  ],
  "cc-forum": [
    "#FEFDF1", // 0
    "#FDFADC", // 1
    "#FBF4B9", // 2
    "#EBE361", // 3
    "#D3C750", // 4
    "#999146", // 5
    "#000000",
    "#000000",
    "#000000",
    "#000000",
  ],
  "neutrals-01": [
    "#FFFFFF", // 0
    "#F9F9F9", // 1
    "#F6F6F5", // 2
    "#F0F0F0", // 3
    "#ECECEC", // 4
    "#D6D6D6", // 5
    "#B5B5B5", // 6
    "#949494", // 7
    "#767676", // 8
    "#4C4C4C", // 9
  ],
  "neutrals-02": [
    "#3D3D3D", // 0
    "#000000", // 1
    "#303030", // 2
    "#000000",
    "#000000",
    "#000000",
    "#000000",
    "#000000",
    "#000000",
    "#000000",
  ],
  "support-error": [
    "#FFDFDF", // 0
    "#F0B3B2", // 1
    "#E58180", // 2
    "#CC0300", // 3
    "#8A1F17", // 4
    "#4C0000", // 5
    "#000000",
    "#000000",
    "#000000",
    "#000000",
  ],
  "support-notice": [
    "#F4F6FE", // 0
    "#E9EDFD", // 1
    "#C7D3FB", // 2
    "#96A8E4", // 3
    "#5B74C7", // 4
    "#3A4C88", // 5
    "#000000",
    "#000000",
    "#000000",
    "#000000",
  ],
  "support-success": [
    "#EEF8F2", // 0
    "#DAF2E4", // 1
    "#6CCB92", // 2
    "#0AA949", // 3
    "#008D39", // 4
    "#005A06", // 5
    "#000000",
    "#000000",
    "#000000",
    "#000000",
  ],
  "support-warning": [
    "#FEFDF1", // 0
    "#FDFADC", // 1
    "#FBF4B9", // 2
    "#EBE361", // 3
    "#D3C750", // 4
    "#999146", // 5
    "#000000",
    "#000000",
    "#000000",
    "#000000",
  ],
  "transparency-01": [
    "rgba(0, 0, 0, 0.1)", // 0
    "rgba(0, 0, 0, 0.2)", // 1
    "rgba(0, 0, 0, 0.3)", // 2
    "rgba(0, 0, 0, 0.4)", // 3
    "rgba(0, 0, 0, 0.5)", // 4
    "rgba(0, 0, 0, 0.6)", // 5
    "rgba(0, 0, 0, 0.7)", // 6
    "rgba(0, 0, 0, 0.8)", // 7
    "rgba(0, 0, 0, 0.9)", // 8
    "rgba(0, 0, 0, 0.97)", // 9
  ],
  "transparency-02": [
    "rgba(246, 246, 245, 0.1)", // 0
    "rgba(246, 246, 245, 0.2)", // 1
    "rgba(246, 246, 245, 0.3)", // 2
    "rgba(246, 246, 245, 0.4)", // 3
    "rgba(246, 246, 245, 0.5)", // 4
    "rgba(246, 246, 245, 0.6)", // 5
    "rgba(246, 246, 245, 0.7)", // 6
    "rgba(246, 246, 245, 0.8)", // 7
    "rgba(246, 246, 245, 0.9)", // 8
    "rgba(246, 246, 245, 0.97)", // 9
  ],
  "transparency-03": [
    "rgba(255, 255, 255, 0.1)", // 0
    "rgba(255, 255, 255, 0.2)", // 1
    "rgba(255, 255, 255, 0.3)", // 2
    "rgba(255, 255, 255, 0.4)", // 3
    "rgba(255, 255, 255, 0.5)", // 4
    "rgba(255, 255, 255, 0.6)", // 5
    "rgba(255, 255, 255, 0.7)", // 6
    "rgba(255, 255, 255, 0.8)", // 7
    "rgba(255, 255, 255, 0.9)", // 8
    "rgba(255, 255, 255, 0.97)", // 9
  ],
} as const satisfies MantineThemeColorsReadonly;
