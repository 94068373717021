import { type FunctionComponent } from "react";

export const Show: FunctionComponent = ({ size = 20 }: { readonly size?: number | undefined }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <g id="show">
      <path
        id="vector"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.70992 9.73663C1.72742 9.68079 3.63908 4.16663 9.99992 4.16663C16.3607 4.16663 18.2724 9.68079 18.2907 9.73663L18.3783 9.99996L18.2899 10.2633C18.2724 10.3191 16.3607 15.8333 9.99992 15.8333C3.63908 15.8333 1.72742 10.3191 1.70908 10.2633L1.62158 9.99996L1.70992 9.73663ZM3.39492 9.99996C3.81325 10.9616 5.54075 14.1666 9.99992 14.1666C14.4566 14.1666 16.1849 10.965 16.6049 9.99996C16.1866 9.03829 14.4591 5.83329 9.99992 5.83329C5.54325 5.83329 3.81492 9.03496 3.39492 9.99996ZM8.23698 8.23702C8.70487 7.76912 9.33822 7.50433 9.99992 7.49996C11.3674 7.49996 12.4999 8.63246 12.4999 9.99996C12.4999 11.3683 11.3674 12.5 9.99992 12.5C8.63158 12.5 7.49992 11.3683 7.49992 9.99996C7.50428 9.33827 7.76908 8.70492 8.23698 8.23702Z"
        fill="currentColor"
      />
    </g>
  </svg>
);
