import { type FunctionComponent } from "react";

type Props = {
  readonly size?: number;
};

export const Heart: FunctionComponent<Props> = ({ size = 20 }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <g id="heart">
      <path
        id="vector"
        d="M10 3.8291C9.09203 2.99418 7.90353 2.5308 6.67002 2.53076C6.01714 2.53144 5.37084 2.66123 4.76831 2.91265C4.16577 3.16408 3.61891 3.53217 3.15919 3.99576C1.19836 5.96493 1.19919 9.04493 3.16086 11.0058L9.27086 17.1158C9.41253 17.3649 9.68586 17.5258 10 17.5258C10.129 17.5245 10.2559 17.493 10.3706 17.4338C10.4852 17.3746 10.5843 17.2894 10.66 17.1849L16.8392 11.0058C18.8009 9.0441 18.8009 5.96493 16.8375 3.99243C16.378 3.52969 15.8316 3.16234 15.2297 2.9115C14.6277 2.66065 13.9821 2.53126 13.33 2.53076C12.0965 2.53096 10.9081 2.99432 10 3.8291ZM15.6592 5.17076C16.9617 6.47993 16.9625 8.52493 15.6609 9.82743L10 15.4883L4.33919 9.82743C3.03752 8.52493 3.03836 6.47993 4.33752 5.1741C4.97086 4.5441 5.79919 4.19743 6.67002 4.19743C7.54086 4.19743 8.36586 4.5441 8.99419 5.17243L9.41086 5.5891C9.48818 5.66655 9.58002 5.728 9.68111 5.76992C9.78221 5.81185 9.89058 5.83343 10 5.83343C10.1095 5.83343 10.2178 5.81185 10.3189 5.76992C10.42 5.728 10.5119 5.66655 10.5892 5.5891L11.0059 5.17243C12.2659 3.91493 14.4009 3.91826 15.6592 5.17076Z"
        fill="currentColor"
      />
    </g>
  </svg>
);
