import { type FunctionComponent } from "react";

export const Hide: FunctionComponent = ({ size = 20 }: { readonly size?: number | undefined }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <g id="hide">
      <path
        id="vector"
        d="M10.0001 15.8334C10.7884 15.8334 11.5084 15.7476 12.1651 15.5992L10.7009 14.1351C10.4734 14.1526 10.2426 14.1667 10.0001 14.1667C5.54094 14.1667 3.81344 10.9617 3.3951 10.0001C3.70923 9.29906 4.13348 8.65284 4.65177 8.08589L3.48677 6.92089C2.2051 8.31006 1.71927 9.70922 1.7101 9.73672C1.65262 9.90786 1.65262 10.0931 1.7101 10.2642C1.7276 10.3192 3.63927 15.8334 10.0001 15.8334ZM10.0001 4.16672C8.46927 4.16672 7.21177 4.49672 6.16344 4.98422L3.08927 1.91089L1.91094 3.08922L16.9109 18.0892L18.0893 16.9109L15.3234 14.1451C17.5018 12.5192 18.2793 10.2992 18.2909 10.2642C18.3484 10.0931 18.3484 9.90786 18.2909 9.73672C18.2726 9.68089 16.3609 4.16672 10.0001 4.16672ZM14.1434 12.9651L12.2434 11.0651C12.4018 10.7401 12.5001 10.3826 12.5001 10.0001C12.5001 8.63256 11.3676 7.50006 10.0001 7.50006C9.6176 7.50006 9.2601 7.59839 8.93594 7.75756L7.42927 6.25089C8.25637 5.96707 9.1257 5.82589 10.0001 5.83339C14.4593 5.83339 16.1868 9.03839 16.6051 10.0001C16.3534 10.5767 15.6334 11.9517 14.1434 12.9651Z"
        fill="currentColor"
      />
    </g>
  </svg>
);
